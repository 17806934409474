import * as React from 'react';
import { useEffect } from 'react';
import {graphql, useStaticQuery} from 'gatsby';
import {useQueryParam, StringParam} from 'use-query-params';
import DOMPurify from 'dompurify';
import Cookies from 'js-cookie';
import { getRapid } from '../../services';

const CommerceExtRedirectPage = () => {
  const ncidUtmMapping = {
    '100001569': { utmSource: 'mail', utmMedium: 'promo', utmCampaign: 'extinboxpromo'},
    '100001570': { utmSource: 'mail', utmMedium: 'promo', utmCampaign: 'tomext'},
    '100001798': { utmSource: 'yahoo', utmMedium: 'susiad', utmCampaign: 'susilaunch'},
    '100001571': { utmSource: 'mail', utmMedium: 'email', utmCampaign: 'extlaunch'},
    '100001826': { utmSource: 'YahooShopping', utmMedium: 'bannerpromo', utmCampaign: 'extlaunch'}
  }
  const { content } = useStaticQuery(query);
  const [ncidIdParam] = useQueryParam('ncid', StringParam);
  const [utmSourceParam] = useQueryParam('utm_source', StringParam);
  const [utmMediumParam] = useQueryParam('utm_medium', StringParam);
  const [utmCampaignParam] = useQueryParam('utm_campaign', StringParam);
  
  const redirectToChromeWebStore = () => {
    const { utm_source, utm_medium, utm_campaign } = getUtmInfo();
    let redirectBaseUrl = 'https://chromewebstore.google.com/detail/ejhnaokakbijjheikhpddhpepahkglen';

    if (utm_source && utm_medium && utm_campaign) {
      redirectBaseUrl += `?utm_source=${utm_source}&utm_medium=${utm_medium}&utm_campaign=${utm_campaign}`
    }

    // Redirecting to Yahoo Shopping extension
    window.location.replace(redirectBaseUrl);
  };

  const getUtmInfo = () => {
    const { ncid, utmSource, utmMedium, utmCampaign } = sanitizeQueryParams()
    // 1. return values from query params if set
    if (utmSource && utmMedium && utmCampaign) {
      return {
        'utm_source': utmSource,
        'utm_medium': utmMedium,
        'utm_campaign': utmCampaign
      };
    } else if (ncid) {
      // 2. return values from mapped config
      const initialNcid = ncid.split("_")[0];
      const utmInfo = ncidUtmMapping[initialNcid];
      if (utmInfo) {
        const { utmSource, utmMedium, utmCampaign } = utmInfo;
        
        return {
          'utm_source': utmSource,
          'utm_medium': utmMedium,
          'utm_campaign': utmCampaign
        };
      }
    }
    
    return {
      'utm_source': undefined,
      'utm_medium': undefined,
      'utm_campaign': undefined
    };
  };
  // This code only runs in the browser. Should be called from useEffect or similar API.
  const sanitizeQueryParams = () => {
    if (typeof window === 'undefined') {
      return {
        ncid: undefined,
        utmSource: undefined,
        utmMedium: undefined,
        utmCampaign: undefined
      };
    }

    return {
      ncid: ncidIdParam ? DOMPurify.sanitize(ncidIdParam) : undefined,
      utmSource: utmSourceParam ? DOMPurify.sanitize(utmSourceParam) : undefined,
      utmMedium: utmMediumParam ? DOMPurify.sanitize(utmMediumParam) : undefined,
      utmCampaign: utmCampaignParam ? DOMPurify.sanitize(utmCampaignParam) : undefined
    };
  }
  
  useEffect(() => {
    const { ncid: sanitizedNcidValue } = sanitizeQueryParams();

    // Rapid will automatically read the query param `ncid` and add to rapid-events
    getRapid(content.config.rapid.init);
    
    if (sanitizedNcidValue) {      
      Cookies.set('ncid', sanitizedNcidValue, {
        secure: true,
        domain: 'downloads.yahoo.com',
        path: '/commerce',
        sameSite: 'Strict',
        expires: 1/48 // 30 mins
      });
    }
    
    redirectToChromeWebStore();
  }, [ncidIdParam]);

  return <></>
};

export default CommerceExtRedirectPage;


export const query = graphql`
    query {
        content: shoppingExtRedirectJson {
            config {
                rapid {
                    init {
                        spaceid
                        keys {
                            pt
                            pct
                            pg_name
                            ver
                        }
                    }
                }
            }            
        }  
    }
`;
